<template>
  <nav class="flex-between-center" @click="dropHide">
    <router-link to="/"
      ><img src="@/assets/img/icon/logo.png" height="48" class="logo"
    /></router-link>
    <div :class="['nav-tab', { mobileShow: navMobileShow }]">
      <img
        v-show="navMobileShow || miniShow"
        @click="
          navMobileShow = false;
          miniShow = false;
        "
        class="nav-close"
        src="@/assets/img/icon/close_white.png"
        width="30"
      />
      <ul :class="['flex-align-center nav-right-part', { miniShow: miniShow }]">
        <!-- <li class="pc-contact">
          <a
            href="https://twitter.com/CastWallet"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="@/assets/img/icon/twitter.svg" width="24" />
          </a>
        </li> -->
        <li v-if="hasSubmit">
          <router-link to="/submit">{{ $t("Submit") }}</router-link>
        </li>
        <li class="dropdown login">
          <a
            v-if="!account || account == 'undefined'"
            class="border-btn"
            @click="login"
            >{{ $t("Connect") }}</a
          >
          <a
            v-else
            class="border-btn active"
            @click.stop="
              dropShow = !dropShow;
              langDropShow = false;
            "
          >
            <span>{{ webUtil.formatStrByDot(account) }}</span>
            <img src="@/assets/img/icon/downward_grey.png" width="16" />
          </a>
          <div class="dropdown-content" v-show="dropShow">
            <router-link to="/collections">{{ $t("MyFavorites") }}</router-link>
            <a @click="logoutAction">{{ $t("Disconnect") }}</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="flex-align-center">
      <!-- <ul class="mobile-contact flex-align-center">
        <li>
          <a
            href="https://twitter.com/CastWallet"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="@/assets/img/icon/twitter.svg" width="32" />
          </a>
        </li>
      </ul> -->
      <img
        class="menu pointer"
        @click.stop="navMobileShow = true"
        src="@/assets/img/icon/menu.png"
        width="32"
      />
      <img
        class="min-menu pointer"
        @click.stop="miniShow = true"
        src="@/assets/img/icon/menu.png"
        width="32"
      />
    </div>
  </nav>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["hasSubmit"],
  data() {
    return {
      langDropShow: false,
      dropShow: false,
      navMobileShow: false,
      miniShow: false,
      langType: [
        {
          type: "en",
          name: "English",
        },
        {
          type: "cn",
          name: "中文",
        },
      ],
    };
  },
  mounted() {
    document.documentElement.onclick = () => {
      this.dropHide();
    };
  },
  methods: {
    ...mapActions(["setLang"]),
    logoutAction() {
      this.logout();
      this.dropHide();
    },
    dropHide() {
      this.langDropShow = false;
      this.navMobileShow = false;
      this.miniShow = false;
      this.dropShow = false;
    },
    toggleLang(lang) {
      this.dropHide();
      //切换语言
      this.setLang(lang);
    },
  },
};
</script>
<style scoped>
nav {
  background: var(--darkColor);
  padding: 8px 24px;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}
nav a {
  color: #fff;
  cursor: pointer;
}
.nav-tab {
  margin-left: 36px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}
.mobile-contact a {
  padding: 0 12px 0 0;
}
.nav-tab > ul > li > a,
.dropdown > p {
  padding: 10px 12px;
  display: block;
}
.dropdown > p {
  display: inline-block;
}
.nav-tab > ul > li > .border-btn {
  padding: 4px 16px;
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
  cursor: pointer;
  display: inline-block;
}
.nav-tab > ul > li > .active {
  padding-left: 36px;
  position: relative;
}
.nav-tab > ul > li > .active:after {
  content: "";
  position: absolute;
  left: 16px;
  top: 11px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--mainColor);
  box-shadow: 0 0 8px 0 var(--mainColor);
}
.login .dropdown-content {
  right: 0;
  left: 12px;
  top: 42px;
  text-align: center;
  max-width: 166px;
}
.nav-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 10001;
}
.mobileShow,
.miniShow {
  position: fixed;
  background: var(--darkColor);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  z-index: 10000;
  padding: 60px 16px 40px;
  overflow-y: auto;
}
.mobileShow > ul {
  display: block;
  text-align: center;
}
.relative {
  position: relative;
}
nav .qrcode {
  position: absolute;
  display: none;
  right: 0;
  top: 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
}
nav li:hover .qrcode {
  display: block;
}
@media (min-width: 1025px) {
  .mobile-contact,
  .min-menu,
  .nav-close {
    display: none;
  }
}
@media (min-width: 769px) {
  .menu {
    display: none;
  }
}
@media (max-width: 1024px) {
  .pc-contact,
  .nav-right-part {
    display: none;
  }
  .miniShow {
    display: block;
    text-align: center;
  }
  .login {
    padding: 12px 0;
  }
  .nav-tab > ul > li > .border-btn {
    margin-left: 0;
  }
  .dropdown-content,
  .login .dropdown-content {
    left: 50%;
    transform: translateX(-50%);
  }
  .login .dropdown-content {
    top: 56px;
  }
}
@media (max-width: 768px) {
  nav {
    padding: 6px 16px 6px 0;
  }
  nav .logo {
    height: 36px;
  }
  .nav-tab,
  .min-menu {
    display: none;
  }
  .mobileShow,
  .nav-right-part {
    display: block;
  }
  .mobileShow > ul {
    display: block;
    text-align: center;
  }
  .nav-tab .dropdown-content {
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
